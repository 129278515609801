import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_6.jpg';
// Section 25 Images ==================
import Blog1 from './../assets/img/jpg/blog_thumbs/blog_thumb_1.jpg';
import Blog2 from './../assets/img/jpg/blog_thumbs/blog_thumb_2.jpg';
import Blog3 from './../assets/img/jpg/blog_thumbs/blog_thumb_3.jpg';





export default class Page6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    componentDidMount() {
        this.loaderShow();
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };

    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>Blogs</h3>
                    </div>
                </section>
                <section className={'section-25'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <Link to="/fly-in-style-and-comfort" className="card">
                                    <img src={Blog1} alt="" className={'card-img'}/>
                                    <div className="card-body">
                                        <h4 className="title">Fly in Style & Comfort with Our Exclusive Membership Program</h4>
                                        <p>Have you ever dreamed of flying in style with access to the highest levels of personal service?
                                            Look no further than Prestige Jet Club's Exclusive</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link to="/taking-flight-with-prestige-jet-club-courses" className="card">
                                    <img src={Blog2} alt="" className={'card-img'}/>
                                    <div className="card-body">
                                        <h4 className="title">Taking Flight with Prestige Jet Club Courses</h4>
                                        <p>Are you interested in taking your love of aviation to the next level? Then look no further than Prestige Jet Club Courses! With our professional instructors and up-to-date aircraft</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4">
                                <Link to="/look-at-private-jet-pricing" className="card">
                                    <img src={Blog3} alt="" className={'card-img'}/>
                                    <div className="card-body">
                                        <h4 className="title">A Look at Private Jet Pricing</h4>
                                        <p>Know your options before you decide something is out of your budget! Concerning private jet pricing, there are three main tiers: basic, premium, and platinum. This article will explore each one in detail. We’ll look at the cost per hour, the amenities offered, and how to determine which level of</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}