import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Images ==================
import BannerImage from './../assets/img/jpg/page_banners/page_banner_4.jpg';
// Section 13 Images ==================
import PlanSide from './../assets/img/jpg/section_bg_9.jpg';
import PlanSeats from './../assets/img/jpg/plane_chairs.jpg';
// Section 14 Images ==================
import planTopIcon from './../assets/img/png/obj_plane_circle_1.png';
import PlanDottedTop from './../assets/img/png/obj_plane_dotted_top.png';
import PlanDottedBottom from './../assets/img/png/obj_plane_dotted_top.png';
// Section 15 Images ==================
import FemaleSmile from './../assets/img/jpg/section_bg_11.jpg';
import MaleFemaleSmile from './../assets/img/jpg/plane_two_hostess.jpg';
import PilotWithSuitcase from './../assets/img/jpg/plane_pilot_hostess_closeup.jpg';





export default class Page4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.loaderShow();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };


    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={BannerImage} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>The Sky is the Limit When You Fly with <br/> Prestige Jet Club!</h3>
                    </div>
                </section>
                <section className="section-13">
                    <img src={PlanSide} alt="" className={'img-left'}/>
                    <div className="content">
                        <p>We are a private jet charter company that prides itself on its highly qualified team of professionals and luxurious services. We pay attention to detail, ensuring our clients travel in style and comfort. Whether you’re traveling for business or fun, we’ll make sure your trip is one you’ll never forget.</p>
                    </div>
                    <img src={PlanSeats} alt="" className={'img-right'}/>
                </section>
                <section className={'section-14'}>
                    <div className="img-wrapper">
                        <img src={planTopIcon} alt="" className={'obj'}/>
                        <img src={PlanDottedTop} alt="" className={'top-obj'}/>
                        <img src={PlanDottedBottom} alt="" className={'bottom-obj'}/>
                    </div>
                    <div className="content">
                        <h1 className="title">When It All Began!</h1>
                        <p>With over 50 years of experience in private charters, a group of esteemed executives came together to start Prestige Jet Club. Our foundation is based on offering world-class customer service and quality experiences by ensuring our passengers access only top-of-the-line aircraft.</p>
                        <p>We offer personalized service and financial sustainability unlike any other company! As long-time private charter users, we curated a way for professionals like us to fly in style without commitment or hassle. We eliminate membership fees and focus on providing the most extensive variety of classes and capacity at your fingertips. If you don’t see your preference in our fleet, we will work to source it or its most similar comparison to meet your expectation.</p>
                        <p>Our fleet consists of advanced luxury jets. We offer several makes and models with various seating capacities to suit your travel needs and meet your comfort level. Complete with In-flight Dining, International Wi-Fi and much more, we cater our service to ensure our customers' utmost safety, comfort, and satisfaction</p>
                    </div>
                </section>
                <section className={'section-15'}>
                    <img src={FemaleSmile} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h1 className="title">It’s All About Teamwork</h1>
                                    <p>At Prestige Jet Club, we consider ourselves your partners. It’s not just about getting you from point A to B; we want you to feel like part of the Prestige Jet Club family while on board the aircraft. We always put your needs first and work with you as a team to ensure you have an easy-going, top-notch experience.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img-wrapper">
                                    <img src={MaleFemaleSmile} alt="" className={'img-1'}/>
                                    <img src={PilotWithSuitcase} alt="" className={'img-2'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}