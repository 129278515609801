import React, { Component } from "react";
import Slider from "react-slick";
import {Form, Container, Button} from 'react-bootstrap';
import $ from "jquery";
// import './../assets/scss/home.scss';

// Banner ==================
import Banner from './../assets/img/jpg/page_banners/page_banner_3.jpg';
// Images section-10 ==================
import ImagePilot from './../assets/img/jpg/plane_pilot_smiling.jpg';
import CheckGuardIcon from './../assets/img/png/icon_check_quard.png';
// Images section-11 ==================
import HandsomePilot from './../assets/img/jpg/section_bg_7.jpg';
import IsBao from './../assets/img/png/logo_is_bao.png';
import IsBaoBG from './../assets/img/jpg/section_bg_6.jpg';
// Images section-12 ==================
import WingmanOperator from './../assets/img/png/logo_wingman.png';
import PilotPose from './../assets/img/png/pilot_pose_left.png';





export default class Page3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.loaderShow();
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    loaderShow() {
        this.setState({loader: true});
        setTimeout(() => {
            this.setState({loader: false});
        }, 3000);
    };


    render() {
        return (
            <main>
                {
                    this.state.loader === true ? <div className={'loader'}><div className={'bar'}><span></span></div></div> : ''
                }
                <section className={'page-hero lg'}>
                    <img src={Banner} className={'bg'}/>
                    <div className={'caption'}>
                        <h3 className={'main-title'}>At Prestige Jet Club, our client's safety is <br/> our top priority!</h3>
                    </div>
                </section>
                <section className="section-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="img-wrapper">
                                    <h1 className={'counter'}><img src={CheckGuardIcon} alt="" className={'icon'}/>100<span>%</span></h1>
                                    <img src={ImagePilot} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="caption">
                                    <p>Our unique Safety Management System (SMS) protocol requires our pilots to hold an FAA-approved airline transport license. We ensure that every variable considered in flight operations goes above and beyond what industry standards or FAA regulations require.</p>
                                    <p>We are a Wyvern Wingman certified company, showing an elevated commitment to safety. This can be seen with our IS-BAO Stage 2 compliance status and our unique SMS protocol, in which each mission runs through a Flight Risk Assessment Tool (FRAT). FRAT is an essential tool for assessing potential risks during each flight. It allows us to develop mitigation strategies if needed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-11">
                    <img src={HandsomePilot} alt="" className={'right-img'}/>
                    <img src={IsBao} alt="" className={'logo'}/>
                    <img src={IsBaoBG} alt="" className={'bg'}/>
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h1 className="title">IS-BAO Stage 2</h1>
                                    <p>The International Standards for Business Aircraft Operations was developed by the International Business Aviation Council (IBAC), with input from its member associations. The IS-BAO is a recommended code of best practices designed to help flight departments worldwide achieve high levels of safety through professionalism while providing them with tools necessary for success, such as technical assistance when needed.
                                        This industry standard based on ICAO standards provides guidance in areas such as aircraft operations with professionalism that meets international guidelines while also meeting Canadian or American regulations. At Prestige Jet Club, we are currently IS-BAO Stage 2 compliant.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-12">
                    <div className="container">
                        <div className="row align-items-lg-end">
                            <div className="col-lg-4 order-2 order-lg-1">
                                <img src={PilotPose} alt="" className={'left-img img-fluid'}/>
                            </div>
                            <div className="col-lg-8 order-1 order-lg-2">
                                <div className="content">
                                    <img src={WingmanOperator} alt="" className={'icon'}/>
                                    <h1 className="title">Wyvern Wingman Certified <br/>Operator</h1>
                                    <p>Wyvern's tools for reliable safety intelligence and informed sourcing have helped leading charter buyers to be safe on the flight. Through The Wingman Rating (TWR), pilots can provide more accurate information about their company when flying with Wyvern-type organizations; this helps ensure quality service by ensuring that every trip goes smoothly without any hassle or worry!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}