import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import './../assets/scss/component/header.scss';
// Images ==================
import Logo from './../assets/img/png/logo/logo.png';
import Menu from './../assets/img/png/icon_menu.png';
import PlanYellow from './../assets/img/svg/icon_plane.svg';
import User from './../assets/img/png/icon_user.png';
import loaderImage from "../assets/img/png/logo/preloader_screen.png";
import Icon_Call from "../assets/img/png/icon_call.png";
import scroll_Top_Arrow from "../assets/img/png/icon_scroll_top_arrow.png";
import $ from "jquery";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navbarExpanded: false
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleClose = this.toggleClose.bind(this);
    }
    toggleOpen() {
        window.scrollTo(0, 0);
        this.setState({ navbarExpanded: !this.state.navbarExpanded });
    }
    toggleClose() {
        this.setState({ navbarExpanded: false });
    }
    componentDidMount() {
        AOS.init({
            once: true
        });

        $(document).on('click', (event) => {
            if ($(event.target).closest('#navbarScroll').length) {

            } else if ($(event.target).closest('.navbar-toggler').length) {

            } else {
                this.toggleClose();
            }
        });

        $(".loader_window").on('load',function () {
            $(".main-title").css({"opacity":"1"});
            setTimeout(function () {
                $('.preloader').on('click', function () {
                    var parent = $(this);
                    parent.addClass('anim');
                    $('body').addClass('loaded');
                    setTimeout(function () {
                        parent.hide();
                    }, 1200)
                });
            }, 100)
        });
        window.history.scrollRestoration = "manual";
        $(window).on('beforeunload', function(){
            setTimeout(function () {
                $(window).scrollTop(0);
            }, 300)
        });
        $(window).on('scroll', function() {
            if ($(window).scrollTop() > 300) {
                $(".scroll_to_top").addClass('show');
            } else {
                $(".scroll_to_top").removeClass('show');
            }
        });
        $(".scroll_to_top").on('click', function(e) {
            $('html,body').animate({
                scrollTop: $("nav.navbar").offset().top}, 300);
        });
    }
    render() {
        return (
            <>
                <section className={'preloader'} onClick={this.sliderStart}>
                    <img src={loaderImage} alt="First slide" className={'slider-img loader_window'}/>
                    <h3 className={'main-title'}>Explore</h3>
                </section>
                <Navbar bg="dark" expand="" expanded={this.state.navbarExpanded}>
                    <Container>
                        <Link className={'navbar-brand'} to="/">
                            <img src={Logo} alt="Prestige" />
                        </Link>
                        <Navbar.Toggle aria-controls="navbarScroll" onClick={this.toggleOpen}>
                            <img src={Menu} alt="" className="icon" />
                            <svg className="plan" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M7.06089 24.1222C6.94957 24.1963 6.8827 24.3213 6.8827 24.455L6.8827 27.0473C6.8827 27.1801 6.94854 27.3042 7.05845 27.3786C7.16836 27.453 7.30802 27.468 7.43125 27.4187L13.7635 24.8858L20.0976 27.4187C20.2208 27.468 20.3605 27.453 20.4704 27.3786C20.5803 27.3041 20.6461 27.1801 20.6461 27.0473L20.6461 24.455C20.6461 24.3213 20.5793 24.1964 20.468 24.1222L16.7567 21.6477L16.7567 16.3581L26.5917 19.917C26.7143 19.9613 26.8508 19.9431 26.9575 19.8683C27.0643 19.7934 27.1278 19.6712 27.1278 19.5408L27.1278 17.086C27.1278 16.9636 27.0717 16.8479 26.9757 16.7721L16.7576 8.70364L16.7567 3.71531C16.7631 3.3192 16.6908 2.92574 16.544 2.55777C16.3967 2.18868 16.1774 1.85262 15.8989 1.5692C15.6203 1.28577 15.2881 1.06066 14.9217 0.906995C14.5552 0.75333 14.1618 0.674188 13.7644 0.674187C13.367 0.674187 12.9736 0.753329 12.6071 0.906995C12.2407 1.06066 11.9085 1.28577 11.6299 1.5692C11.3514 1.85262 11.1321 2.18868 10.9848 2.55777C10.838 2.92575 10.7657 3.31921 10.7721 3.71533L10.7721 8.84023L0.553142 16.9077C0.457013 16.9836 0.400949 17.0994 0.400998 17.2218L0.401915 19.5419C0.401967 19.6716 0.464883 19.7932 0.570705 19.8681C0.676527 19.9431 0.812122 19.9621 0.934475 19.9191L10.773 16.4634L10.773 21.6486L7.06089 24.1222Z" fill="black" stroke="#EDC55C" stroke-width="0.8" stroke-linejoin="round"/>
                            </svg>
                            <span className="custom_text">Menu</span>
                        </Navbar.Toggle>
                        <Button className={'user-btn d-none'}>
                            <img src={User} alt="" className="icon" />
                        </Button>
                        <Navbar.Collapse id="navbarScroll">
                            <Navbar.Toggle className={'close-btn'} aria-controls="navbarScroll" onClick={this.toggleOpen}>
                                <span className="custom_text">Close</span>
                                <img src={PlanYellow} alt="" className={'plan'}/>
                            </Navbar.Toggle>
                            <Nav className="menu" navbarScroll>
                                <Link to="charter" onClick={this.toggleOpen}>Charter</Link>
                                <NavDropdown title="Culture" className={'dropdown-1'}>
                                    <Link to="/about-us" onClick={this.toggleOpen}>About Us</Link>
                                    <Link to="/safety-and-certificates" onClick={this.toggleOpen}>Safety & Certifications</Link>
                                    <Link to="/testimonials" onClick={this.toggleOpen}>Testimonials</Link>
                                    <Link to="/blogs" onClick={this.toggleOpen}>Blogs</Link>
                                </NavDropdown>
                                <NavDropdown title="Fleet" className={'dropdown-2'}>
                                    <div className={'my-auto'}>
                                        <h5 className="title">Long-Range</h5>
                                        <Link to="/bombardier-global-8000" onClick={this.toggleOpen}>Bombardier Global 8000</Link>
                                        <Link to="/gulf-stream-G550" onClick={this.toggleOpen}>Gulfstream G550</Link>
                                        <Link to="/bombardier-global-7500" onClick={this.toggleOpen}>Bombardier Global 7500</Link>
                                        <Link to="/bombardier-global-6000" onClick={this.toggleOpen}>Bombardier Global 6000</Link>
                                        <Link to="/global-express" onClick={this.toggleOpen}>Bombardier Global Express</Link>
                                        <Link to="/bombardier-global-5500" onClick={this.toggleOpen}>Bombardier Global 5500</Link>
                                        <br/>
                                        <h5 className="title" onClick={this.toggleOpen}>Large Jets</h5>
                                        <Link to="/airbus-A380" onClick={this.toggleOpen}>Airbus A380</Link>
                                        <Link to="/boeing-business-jet-bbj-737-max" onClick={this.toggleOpen}>Boeing Business Jet- BBJ 737 MAX</Link>
                                        <Link to="/airbus-a330neo" onClick={this.toggleOpen}>Airbus A330neo</Link>
                                        <Link to="/bombardier-challenger-650" onClick={this.toggleOpen}>Bombardier Challenger 650</Link>
                                        <br/>
                                        <h5 className="title">Super Midsize Jets</h5>
                                        <Link to="/bombardier-challenger-601" onClick={this.toggleOpen}>Bombardier Challenger 601</Link>
                                        <Link to="/bombardier-challenger-350" onClick={this.toggleOpen}>Bombardier Challenger 350</Link>
                                        <Link to="/cessna-citation-longitude" onClick={this.toggleOpen}>Cessna Citation Longitude</Link>
                                        <br/>
                                        <h5 className="title">Midsize Jets</h5>
                                        <Link to="/cessna-citation-sovereign" onClick={this.toggleOpen}>Cessna Citation Sovereign</Link>
                                        <Link to="/cessna-citation-excel" onClick={this.toggleOpen}>Cessna Citation Excel</Link>
                                        <Link to="/cessna-citation-xsl" onClick={this.toggleOpen}>Cessna Citation XSL</Link>
                                        <br/>
                                        <h5 className="title">Light</h5>
                                        <Link to="/embraer-phenom-300-e" onClick={this.toggleOpen}>Embraer Phenom 300/E</Link>
                                        <Link to="/beech-craft-premier-i" onClick={this.toggleOpen}>Beechcraft Premier I</Link>
                                        <Link to="/cirrus-vision-jet" onClick={this.toggleOpen}>Cirrus Vision Jet</Link>
                                    </div>
                                </NavDropdown>
                                <Link to="/membership" onClick={this.toggleOpen}>Membership</Link>
                                <Link to="/learn-how-to-fly" onClick={this.toggleOpen}>Learn How to Fly <span className={'small'}>(Certified Aviation Training)</span></Link>
                                <Link to="/get-a-quote" onClick={this.toggleOpen}>Get a Quote</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <a href={'tel:9549518895'} className="call">
                    <img src={Icon_Call} alt=""/>
                </a>
                <button className="scroll_to_top">
                    <img src={scroll_Top_Arrow} alt=""/>
                </button>
            </>
        );
    }

}
